<template>
  <div class="keywords">
    <div>
      <div class="keywords-input">
        <input
          v-model="keyword"
          @keyup.space="hanldeKeySpace"
          @keyup.enter="hanldeKeyEnter"
          placeholder="请输入内容"
        />
        <van-button
          type="info"
          :disabled="inputDisabled"
          @click="addKeyWords"
          size="small"
          >添加</van-button
        >
      </div>
      <div class="keywords-content">
        <div class="keyword-list-box">
          <div class="keyword-list">
            <div
              class="keyword-box"
              :class="item === keyword ? 'keyword-box-light' : ''"
              v-for="(item, i) in keywords"
              :key="i"
            >
              <span>{{ item }}</span>
              <van-icon name="cross" @click="deleteKeyWords(i)" />
            </div>
          </div>
        </div>
        <div class="keywords-list-bottom">
          <span>共{{ keywords.length }}个</span>
          <span style="cursor:pointer" @click="deleteAll">清空</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapMutations } from 'vuex'
export default {
  name: 'keywords',
  props: [],
  data() {
    return {
      keyword: '',
      inputDisabled: true,
      keywords: []
    }
  },
  computed: {
    ...mapState('ana', ['anaTwoKeyDown'])
  },
  methods: {
    ...mapMutations('ana', ['SET_ANATWOKEYDOWN']),
    // 处理空格键
    hanldeKeySpace(e) {
      if (this.keyword === ' ') {
        this.keyword = ''
      } else if (this.keyword !== '' && this.keyword.indexOf(' ') > -1) {
        this.addKeyWords()
      }
    },
    // 处理回车键
    hanldeKeyEnter(e) {
      if (this.keyword === '') {
      } else {
        this.addKeyWords()
      }
    },
    // 添加关键词
    addKeyWords() {
      const keywords = this.keywords
      keywords.push(this.keyword.trim())
      // this.$bus.$emit('updateKeywords', keywords)
      this.SET_ANATWOKEYDOWN(keywords)
      this.keyword = ''
    },
    // 删除关键词
    deleteKeyWords(i) {
      const keywords = this.keywords
      keywords.splice(i, 1)
      // this.$bus.$emit('updateKeywords', keywords)
      this.SET_ANATWOKEYDOWN(keywords)
    },
    // 清空所有关键词
    deleteAll() {
      // this.$bus.$emit('updateKeywords', [])
      this.SET_ANATWOKEYDOWN([])
    },
    // 关键词匹配高亮
    highlight() {}
  },
  mounted() {
    this.keywords = this.anaTwoKeyDown.slice(0)
  },
  created() {},
  watch: {
    anaTwoKeyDown(val) {
      this.keywords = val.slice(0)
    },
    keyword(val) {
      if (val) {
        this.inputDisabled = false
      } else {
        this.inputDisabled = true
      }
    }
  }
}
</script>
<style lang="stylus" scoped>
.keywords
  position: relative;
  & .keywords-input
    display flex
    align-items center
    & > input
      flex 1
      height: 35px
      border-radius 5px
      border 1px solid #CACACA
      margin-right 10px
  & .keywords-content
    width: 100%;
    height 150px;
    border 1px solid #CACACA;
    margin-top: 20px
    border-radius 5px
    display flex
    flex-direction column
    padding 10px
    box-sizing border-box
    & .keywords-list-bottom
      display flex
      align-items center
      justify-content flex-end
      & > span
        margin-left 10px
        color #CACACA
    & .keyword-list-box
      flex 1
      overflow auto
    & .keyword-list
      display flex
      align-items center
      flex-wrap wrap
      & .keyword-box-light
        background red !important
        color #FFF !important
      & .keyword-box
        background #D9ECFF
        color #409EFF
        font-size 13px
        padding 5px 10px
        margin-right 10px
        margin-bottom 5px
        border-radius: 2px;
        display flex
        align-items center
        & .el-icon-close
          cursor: pointer;
  & .keywords-btn
    display flex
    align-items center
    justify-content center
    margin-top 20px
    padding-bottom 20px
</style>
