var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "keywords" }, [
    _c("div", [
      _c(
        "div",
        { staticClass: "keywords-input" },
        [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.keyword,
                expression: "keyword",
              },
            ],
            attrs: { placeholder: "请输入内容" },
            domProps: { value: _vm.keyword },
            on: {
              keyup: [
                function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "space", 32, $event.key, [
                      " ",
                      "Spacebar",
                    ])
                  )
                    return null
                  return _vm.hanldeKeySpace.apply(null, arguments)
                },
                function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  )
                    return null
                  return _vm.hanldeKeyEnter.apply(null, arguments)
                },
              ],
              input: function ($event) {
                if ($event.target.composing) return
                _vm.keyword = $event.target.value
              },
            },
          }),
          _c(
            "van-button",
            {
              attrs: {
                type: "info",
                disabled: _vm.inputDisabled,
                size: "small",
              },
              on: { click: _vm.addKeyWords },
            },
            [_vm._v("添加")]
          ),
        ],
        1
      ),
      _c("div", { staticClass: "keywords-content" }, [
        _c("div", { staticClass: "keyword-list-box" }, [
          _c(
            "div",
            { staticClass: "keyword-list" },
            _vm._l(_vm.keywords, function (item, i) {
              return _c(
                "div",
                {
                  key: i,
                  staticClass: "keyword-box",
                  class: item === _vm.keyword ? "keyword-box-light" : "",
                },
                [
                  _c("span", [_vm._v(_vm._s(item))]),
                  _c("van-icon", {
                    attrs: { name: "cross" },
                    on: {
                      click: function ($event) {
                        return _vm.deleteKeyWords(i)
                      },
                    },
                  }),
                ],
                1
              )
            }),
            0
          ),
        ]),
        _c("div", { staticClass: "keywords-list-bottom" }, [
          _c("span", [_vm._v("共" + _vm._s(_vm.keywords.length) + "个")]),
          _c(
            "span",
            {
              staticStyle: { cursor: "pointer" },
              on: { click: _vm.deleteAll },
            },
            [_vm._v("清空")]
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }