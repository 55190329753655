<template>
  <div class="tree_ctn">
    <div
      class="zz"
      :style="{
        opacity: data.flag ? '.5' : '0',
        left: data.flag ? '0' : '-100%',
      }"
      @click="cancel"
    ></div>
    <div class="treeSelect" :style="{ left: data.flag ? '0' : '-100%' }">
      <div class="tree-keywords" v-if="parent === 'ana'">
        <keywords :keywords.sync="input_keyword" />
      </div>
      <!-- <div class="tree">
        <ul class="titles">
          <li
            v-for="(item, index) in names"
            :style="{
              'background-color': index === doubleIndex[0] ? 'white' : ''
            }"
            @click="changeOne(item, index)"
            :key="index"
          >
            {{ item }}
          </li>
        </ul> -->
      <!-- <ul class="item_ctn">
          <div class="tip" v-if="twicedata.length === 0">
            <load :showflag="0"></load>
          </div>
          <li class="item" v-for="(item, index) in twicedata" :key="index">
            <div @click="changeTwo(index)" style="overflow:hidden;">
              <i
                @click="
                  changeflag(item.label, index, Boolean(item.child), item.flag)
                "
              >
                <checkBox v-model="item.flag"></checkBox>
              </i>
            </div> -->
      <!-- <div @click="next(item.label,index,Boolean(item.child))"> -->
      <!-- <div @click="next(item, index)">
              {{ item.label }}&nbsp;&nbsp;({{ item.num }})<label
                v-if="item.child"
                ><i class="iconfont icon-child-off" v-if="item.child.length"></i
              ></label>
            </div>
          </li>
        </ul> -->
      <!-- </div> -->
      <!-- 筛选 -->
      <van-collapse v-model="activeNames">
        <van-collapse-item
          v-for="itema in currentData"
          :key="itema.title"
          :name="itema.title"
        >
          <template #title>
            <div style="display: flex">
              <span style="flex: 2">{{ itema.title }} </span>
              <van-field
                v-if="itema.title === '法院'"
                style="flex: 5"
                class="search-field"
                v-model="searchF"
                @input="handleBlur($event, 'searchF')"
                placeholder="请输入"
              />
              <van-field
                v-else-if="itema.title === '案由'"
                style="flex: 5"
                class="search-field"
                v-model="searchA"
                @input="handleBlur($event, 'searchA')"
                placeholder="请输入"
              />
              <van-field
                v-else-if="itema.title === '地域'"
                style="flex: 5"
                class="search-field"
                v-model="searchD"
                @input="handleBlur($event, 'searchD')"
                placeholder="请输入"
              />
            </div>
          </template>
          <van-checkbox-group v-model="result">
            <van-cell-group
              v-if="
                itema.title !== '效力级别' &&
                itema.title !== '法院地域' &&
                itema.title !== '案由' &&
                itema.title !== '检察机关' &&
                itema.title !== '地域'
              "
            >
              <van-cell
                v-for="item in itema.data"
                clickable
                :key="item.label"
                @click="toggle()"
              >
                <template #title>
                  <div>
                    {{ item.label }}
                    <span>({{ item.num }})</span>
                  </div>
                </template>
                <template #right-icon>
                  <van-checkbox :name="item" ref="checkboxes" />
                </template>
              </van-cell>
            </van-cell-group>
            <!-- 法院地域 -->
            <!-- <van-cell-group v-if="itema.title === '法院地域'">
              <van-cell
                v-for="item in itema.data"
                clickable
                :key="item.label"
                :title="item.label"
                @click="toggle()"
              >
                <template #right-icon>
                  <van-checkbox :name="item" ref="checkboxes" />
                </template>
              </van-cell>
            </van-cell-group> -->
            <ul
              id="demo"
              v-if="
                itema.title === '效力级别' ||
                itema.title === '法院地域' ||
                itema.title === '案由' ||
                itema.title === '检察机关' ||
                itema.title === '地域'
              "
            >
              <tree-item
                class="item"
                v-for="(itemb, index) in itema.data"
                :key="index + 'as'"
                :data="itemb"
                :title="itema.title"
              ></tree-item>
            </ul>
          </van-checkbox-group>
        </van-collapse-item>
      </van-collapse>
    </div>
    <div class="ttl">
      <!-- <span @click="cancel, delData" -->
      <div class="ttl-cancel">
        <span @click="delData"
          ><span style="color: #4b9efb">取消</span>({{ result.length }})</span
        >
      </div>
      <!-- <span>共{{data.total_nums}}篇</span> -->
      <div class="ttl-determine">
        <span class="ttl-deterspan" @click="emit">确定</span>
      </div>
    </div>
  </div>
</template>
<script>
// import checkBox from './checkBox'
// import load from './loading'
import treeItem from './treeGlmb'
import keywords from './keywords'
export default {
  components: { treeItem, keywords },
  props: ['data', 'keywords', 'input_keyword', 'parent'],
  model: {
    prop: 'data',
    event: 'changeflag',
  },
  data() {
    return {
      currentData: [],
      checked: false,
      names: [],
      doubleIndex: [0],
      currentStore: [],
      twicedata: [],
      preventUpdate: false,
      filterData: [],
      treeCount: 0,
      activeNames: [],
      resultVrg: [],
      result: [],
      treeGlmbData: [],
      list: ['a', 'b'],
      searchF: '',
      searchA: '',
      searchD: '',
      pArr: [],
      searchArr: [
        {
          searchName: 'searchF',
          title: '法院',
        },
        {
          searchName: 'searchD',
          title: '地域',
        },
        {
          searchName: 'searchA',
          title: '案由',
        },
      ],
    }
  },
  methods: {
    handleBlur(e, searchName) {
      const searchWord = this[searchName]
      if (!searchName) {
        this.currentData = this.data.data
        return
      }
      this.activeNames.push(searchWord)
      // console.log(searchName, searchWord, 'handleChange')
      const search = this.searchArr.find((v) => v.searchName === searchName)
      const findIndex = this.data.data.findIndex((f) => f.title === search.title)
      const arr = []

      this.data.data.forEach((item, index) => {
        if (index === findIndex && item.data) {
          this.pArr = []
          const obj = { title: item.title, flag: item.flag, data: [] }
          window.console.log(item.data, 'item.data')
          this.pArr = this.findNestWord(item.data, searchWord)
          obj.data = this.pArr
          window.console.log(this.pArr, 'pArr')
          arr.push(obj)
        } else {
          arr.push(item)
        }
      })
      this.currentData = arr
    },
    // 多层寻找 '合同'
    deepFind(data, searchWord, parent = null) {
      // data.forEach((v) => {
      //   const pItem = parent || v
      //   if (v.child.length === 0 && v.label.indexOf(searchWord) >= 0) {
      //     this.pArr.push(pItem)
      //   } else {
      //     this.deepFind(v.child, searchWord, v)
      //   }
      //   // return this.pArr
      // })
    },
    // 案由
    findNestWord(arr, searchWord) {
      const list = []
      const findWord = (arr, searchWord) => {
        arr.forEach((item) => {
          const { label, child } = item
          // const p = parent || item
          if (label.includes(searchWord)) {
            list.push(item)
          }
          if (Array.isArray(child) && child.length > 0) {
            findWord(child, searchWord)
          }
        })
      }
      findWord(arr, searchWord)
      return list
    },
    changeTwo(index) {},
    // 里层点击checked触发事件
    changeflag(name, index, flag, showflag) {
      this.treeCount = 0
      this.treeCountCl(this.data.data)
      // if (flag && showflag) {
      // let res = this.getfloor(name, this.doubleIndex[0])
      // this.repeat(res, (item, index) => {
      //   this.$set(item, 'flag', false)
      // })
      // }
    },
    // 点击确认按钮
    // 原按flag判断选中

    emit() {
      // console.log('emit-----')
      console.log(this.data.data)
      // 处理数据格式
      this.dataFormatFn()
      // this.recursionKeyHandle(this.data.data, this.data.title)
      console.log('filterdata', this.filterData)
      this.$emit('search', this.filterData)
      this.treeCount = 0
      this.data.data = []
      this.filterData = []
      this.cancel()
    },
    // 处理数据格式
    // filterdata数据结构{ [id:"attr_普通案例",key:'attr',label:'案件属性',value:['普通案例']] }
    dataFormatFn() {
      // this.filterdata  根据key来判断
      console.log('dataformatffn')
      for (var i of this.result) {
        console.log(i)
        this.filterData.push({
          id: `${i.key}_${i.label}`,
          label: this.getTitleData(i.key), // label需要获取
          key: i.key,
          num: i.num,
          color: true,
          value: [i.label],
        })
      }
      for (var j of this.treeGlmbData) {
        this.filterData.push({
          id: `${j.key}_${j.label}`,
          label: j.title, // label需要获取
          key: j.key,
          num: j.num,
          value: [j.label],
        })
      }
    },
    // 获取title
    getTitleData(key) {
      console.log('gettitledata')
      for (var i of this.data.data) {
        console.log(i)
        for (var j of i.data) {
          if (j.key === key) {
            return i.title
          } else {
            break
          }
        }
      }
    },
    makeFolder(item) {
      this.addItem(item)
    },
    addItem(item) {
      item.children.push({
        name: 'new stuff',
      })
    },
    // 处理选中地域数据
    treeGlmbDataFilter(data, check) {
      let checkNum = 0
      // treeGlmbData
      if (check) {
        // 查找变量中是否存在 存在则不添加
        for (var i of this.treeGlmbData) {
          if (i.label === data.label) {
            checkNum += 1
            break
          }
        }
        if (checkNum === 0) {
          console.log('添加')
          this.treeGlmbData.push(data)
        }
      } else {
        // 移除
        let numa = 0
        for (var j of this.treeGlmbData) {
          if (j.label === data.label) {
            console.log('删除')
            this.treeGlmbData.splice(numa, 1)
            break
          }
          numa += 1
        }
      }
    },
    toggledom() {},
    // 点击事件
    toggle(index) {
      setTimeout(() => {
        console.log(this.result)
      }, 100)
      // 接口发送
    },
    recursionKeyHandle(data, title = '') {
      // 判断flag 添加到filterdata中
      data.forEach((item) => {
        if (item.flag) {
          this.filterData.push({
            id: `${item.key}_${item.label}`,
            label: title,
            key: item.key,
            num: item.num,
            value: [item.label],
          })
        } else {
          if (item.data) {
            this.recursionKeyHandle(item.data, item.title)
          } else if (item.child) {
            this.recursionKeyHandle(item.child, item.child.title)
          }
        }
      })
    },
    // 外层筛选列表
    changeOne(name, index) {
      let _num = null
      if (index === this.doubleIndex[0] && this.currentStore[index].length > 1) {
        if (this.currentStore[index].length > 1) {
          this.currentStore[index].pop()
          _num = this.currentStore[index].pop()
        }
        const res = this.getfloor(name, index)
        this.twicedata = res[_num].child || res[_num].data
        this.names[index] = res[_num].label || res[_num].title
        // if (_num) {
        this.currentStore[index].push(_num)
        // }
        return false
      }
      this.doubleIndex[0] = index
      // let res = this.getfloor(name, index)
      const res = this.getfloor('', index)
      this.twicedata = res
      this.twicedata.forEach((item, index) => {
        this.$set(this.twicedata[index], 'flag', item.flag)
      })
      console.log('aaaa', this.twicedata)
    },
    // 点击取消按钮触发事件
    delData() {
      // 点击取消清空选择项
      this.treeCount = 0
      this.data.data = []
      this.filterData = []
      this.$emit('delData', this.filterData)
      this.cancel()
    },
    treeCountCl(data) {
      data.forEach((item) => {
        if (item.flag) {
          this.treeCount++
        } else {
          if (item.data || item.child) {
            this.treeCountCl(item.data || item.child)
          }
        }
      })
    },
    cancel() {
      this.treeCount = 0
      this.data.data = []
      this.filterData = []
      console.log('test lok', {
        data: this.data.data,
        flag: false,
        title: this.data.title,
      })
      this.$emit('changeflag', {
        data: this.data.data,
        flag: false,
        title: this.data.title,
      })
    },
    next(name, index) {
      if (name.child && name.child.length > 0) {
        // console.log(this.doubleIndex, 54)
        const res = this.getfloor(name.label, this.doubleIndex[0])
        this.$set(this.names, this.doubleIndex[0], name.label)
        this.twicedata = res
      }
    },
    getfloor(name, index) {
      let res = this.data.data
      let _result = null
      this.currentStore[index].forEach((item) => {
        res = res[item].child ? res[item].child : res[item].data
        _result = res
      })
      for (let i = 0; i < res.length; i++) {
        const item = res[i]
        if (item.label === name || item.title === name) {
          this.currentStore[index].push(i)
          return item.data || item.child
        }
      }
      return _result || res
    },
    repeat(data, callback) {
      let res = data
      for (let i = 0; i < res.length; i++) {
        const item = res[i]
        callback(item, i)
        if (item.data || item.child) {
          res = res.concat(item.data || item.child)
        }
      }
    },
  },
  mounted() {
    this.$bus.$on('tree_glmb_click', (data, check) => {
      console.log('tree-glmb-click')
      console.log(data)
      console.log(check)
      // 处理数据 选中为true 取消为false
      this.treeGlmbDataFilter(data, check)
    })
    this.currentData = this.data.data
  },
  watch: {
    'data.data': {
      immediate: true,
      deep: true,
      handler(val) {
        console.log(val)
        this.currentData = val
        setTimeout(() => {
          if (this.preventUpdate) {
            // this.preventUpdate = false
            return
          }
          this.twicedata = []
          if (val[0]) {
            this.name = val[0].title
          }
          this.names = []
          if (!this.data.data.length) {
            return []
          }
          this.repeat(this.data.data, (item, index) => {
            if (this.keywords.indexOf(item.key) > -1) {
              // this.$set(item, 'flag', true)
              this.$set(item, 'flag', false)
            } else {
              this.$set(item, 'flag', false)
            }
          })
          this.twicedata = this.data.data[0].data
          this.data.data.forEach((item, index) => {
            this.names.push(item.title)
            this.currentStore.push([index])
          })
          this.preventUpdate = true
        })
      },
    },
  },
}
</script>
<style lang="stylus">
.tree_ctn {
  position: relative;

  .tree-keywords {
    padding: 10px;
    box-sizing: border-box;
  }

  .zz {
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0px;
    top: 0px;
    background-color: black;
    opacity: 0.5;
    transition: all 0.5s;
    z-index: 10001;
  }

  .treeSelect {
    width: 75%;
    height: 100%;
    position: fixed;
    overflow: auto;
    background-color: white;
    top: 0px;
    right: 0px;
    transition: all 0.5s;
    z-index: 10001;
    padding-bottom: 50px;

    .search-field {
      padding: 2px;
    }
  }

  .ttl {
    width: 75%;
    height: 50px;
    line-height: 50px;
    position: fixed;
    bottom: 0;
    left: 0;
    display: flex;
    box-sizing: border-box;
    // padding 0px 5px 0px 5px
    justify-content: space-between;
    border-bottom: 1px solid #f6f6f6;
    z-index: 10001;

    div {
      span {
        font-size: 14px;
      }

      span:nth-child(1) {
        // color #666666
      }

      span:nth-child(3) {
        color: #4B9EFB;
      }
    }
  }

  .tree {
    width: 100%;
    height: calc(100% - 50px);

    .titles {
      width: 35%;
      height: 100%;
      overflow: auto;
      background: #f6f6f6;
      float: left;

      li {
        width: 100%;
        height: 40px;
        line-height: 40px;
        transition: all 0.5s;
        text-align: center;
        color: #333333;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }

    .item_ctn {
      width: 65%;
      height: 100%;
      overflow: auto;
      float: right;

      .tip {
        margin-top: 10px;
      }

      .item {
        width: 100%;
        display: flex;
        justify-content: left;
        box-sizing: border-box;
        padding: 10px;
        vertical-align: middle;

        div:nth-child(1) {
          margin-top: 1px;
          padding-right: 2px;
          padding-bottom: 4px;
        }

        div:nth-child(2) {
          margin-right: 3px;
          margin-left: 1px;
        }

        div {
          line-height: 16px;
          margin-right: 5px;
          font-size: 14px;
        }
      }
    }
  }
}
</style>
<style scoped>
.ttl-cancel {
  padding-left: 20px;
  width: 70%;
  background: #f2f2f2;
}
.ttl-determine {
  /* padding-left: 70%; */
  text-align: center;
  width: 30%;
  background: #0066ff;
}
.ttl-determine > span {
  color: #fff;
}

::v-deep .van-collapse-item__content {
  padding: 0.32rem 0.32rem 0.32rem 0;
}
::v-deep .van-cell.van-cell--clickable.van-collapse-item__title {
  background: #f8f8f8;
}
/* ::v-deep .van-cell.van-cell--clickable.van-collapse-item__title ::after {
  border-top: 0px;
} */
::v-deep .van-collapse-item--border::after {
  border: none;
}
::v-deep .van-cell::after {
  border: none;
}
</style>
