<template>
  <li>
    <span class="tree-glmb">
      <span
        class="tree-glmb-iconsp"
        @click="toggle"
        v-if="hasChild"
        v-show="!isOpen"
      >
        <svg
          t="1590398141652"
          class="icon"
          viewBox="0 0 1024 1024"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          p-id="11279"
        >
          <path
            d="M800 160a64 64 0 0 1 64 64v576a64 64 0 0 1-64 64h-576a64 64 0 0 1-64-64v-576a64 64 0 0 1 64-64h576z m0 64h-576v576h576v-576zM512 352a32 32 0 0 1 32 32v96H640a32 32 0 1 1 0 64H544V640a32 32 0 1 1-64 0V544H384a32 32 0 0 1 0-64h96V384a32 32 0 0 1 32-32z"
            p-id="11280"
            fill="#707070"
          ></path>
        </svg>
      </span>
      <span
        class="tree-glmb-iconsp"
        @click="toggle"
        v-if="hasChild"
        v-show="isOpen"
      >
        <svg
          t="1590398094196"
          class="icon"
          viewBox="0 0 1024 1024"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          p-id="9830"
        >
          <path
            d="M746.666667 490.666667h-469.333334a21.376 21.376 0 0 0 0 42.666666h469.333334c12.8 0 21.333333-8.533333 21.333333-21.333333s-10.666667-21.333333-21.333333-21.333333z"
            p-id="9831"
            fill="#707070"
          ></path>
        </svg>
      </span>
      <!-- 节点 -->
      <span v-if="hasChild">
        <van-checkbox-group v-model="result">
          <van-checkbox
            :name="data.label"
            v-if="hasChild"
            @change="glmbc"
            class="tree-v-c-b"
          >
            {{ data.label }}({{ data.num }})
          </van-checkbox>
        </van-checkbox-group>
      </span>
      <van-checkbox-group v-model="result">
        <van-checkbox
          class="tree-v-c-b"
          :name="data.label"
          v-if="!hasChild"
          @change="glmbc"
          >{{ data.label }}({{ data.num }})
        </van-checkbox>
      </van-checkbox-group>
      <!-- <van-cell-group v-if="!hasChild">
        <van-cell clickable @click="toggle()">
          <template #title>
            <div>
              {{ data.label }}
            </div>
          </template>
          <template #right-icon>
            <van-checkbox :name="data" ref="checkboxes" />
          </template>
        </van-cell>
      </van-cell-group> -->
    </span>
    <ul v-show="isOpen" v-if="hasChild && initNum === 1">
      <tree-item
        v-for="(item, index) in data.child"
        :data="item"
        :key="index"
        :title="title"
      ></tree-item>
    </ul>
  </li>
</template>

<script>
export default {
  name: 'TreeItem', // 递归组件必须有name
  props: {
    data: {
      type: [Object, Array], // 多个可能的类型
      required: true
    },
    // label、children 默认值
    treeProps: {
      type: Object,
      default: () => ({
        children: 'children',
        label: 'label'
      })
    },
    title: String
  },
  data() {
    return {
      isOpen: false,
      result: [],
      initNum: 0
    }
  },
  computed: {
    // 判断当前级别是否还有children
    hasChild() {
      return this.data.child && this.data.child.length
    }
  },
  watch: {
    checked: {
      deep: true,
      handler(nv) {
        console.log(nv)
      }
    },
    isOpen(nv) {
      if (nv === true) {
        this.initNum = 1
      }
    },
    result(nv, no) {
      // this.data.label = [this.data.label]
      const newdata = JSON.parse(JSON.stringify(this.data))
      // newdata.label = [this.title + ':', newdata.label]
      console.log('result', newdata)
      newdata.title = this.title
      if (nv[0]) {
        this.$bus.$emit('tree_glmb_click', newdata, true)
      } else {
        this.$bus.$emit('tree_glmb_click', newdata, false)
      }
      // this.$bus.$emit('tree_glmb_click', this.data, nv[0] ? 1 : false)
    }
  },
  methods: {
    // 点击子菜单也要判断是否有children，有就展开
    toggle() {
      if (this.hasChild) {
        this.isOpen = !this.isOpen
      }
    },
    glmbc() {
      console.log('改变了')
      console.log(this.data)
    }
  },
  mounted() {}
}
</script>

<style scoped>
ul {
  list-style: none;
  margin: 10px 0;
  padding-left: 10px;
}
li {
  color: #323233;
}
li > span {
  cursor: pointer;
  font-size: 14px;
  line-height: 20px;
}
.tree-glmb {
  line-height: 0.64rem;
  background-color: #fff;
  display: flex;
  box-sizing: border-box;
  width: 100%;
  padding: 0.266667rem 0rem;
  overflow: hidden;
  color: #323233;
  position: relative;
}
.tree-v-c-b > :first-child {
  position: absolute;
  right: 0;
}
.van-checkbox__icon {
  font-size: 15px;
}
</style>
<style scoped>
.icon {
  width: 20px;
  height: 20px;
}
::v-deep .van-checkbox__label {
  margin-right: 1px;
}
.tree-glmb-iconsp {
  position: absolute;
  width: 20%;
}
.van-checkbox-group {
  margin-left: 30px;
}
.van-hairline--top-bottom::after,
.van-hairline-unset--top-bottom::after {
  border: none;
}
::v-deep .van-cell::after {
  border: none;
}
</style>
